class NotificationManager {
    constructor() {
        this.LOADING_MESSAGE = '<i class="fa fa-bell-o"></i><strong>Loading</strong> page Do not close this page...';
        this.INNER_DATA_MESSAGE = '<i class="fa fa-bell-o"></i><strong>Loading</strong> Inner Data.';
    }

    createNotify() {
        return $.notify(this.LOADING_MESSAGE, {
            type: 'theme',
            allow_dismiss: true,
            delay: 2000,
            showProgressbar: true,
            timer: 300,
            animate:{
                enter:'animated fadeInDown',
                exit:'animated fadeOutUp'
            }
        });
    }

    updateNotify(notify) {
        setTimeout(() => {
            notify.update('message', this.INNER_DATA_MESSAGE);
        }, 1000);
    }
}

export default NotificationManager;
