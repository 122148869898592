<template>
    <create-project is-edit="true" :project="projectItem" @update-project="updateProjectItem">
        <template v-slot:buttons_block>
            <div class="row">
                <div class="col">
                    <div class="text-end">
                        <button class="btn btn-outline-info me-3" @click="updateProject()">Save</button>
                        <button class="btn btn-outline-dark" type="button" @click="back()"><< Back</button>
                    </div>
                </div>
            </div>
        </template>
    </create-project>
    <div class="col-md-12">
        <div class="card">
            <div class="card-header pb-0">
                <h5 class="card-title">Attached companies</h5>
            </div>
            <div class="card-body o-hidden">
                <div class="col-md-3 mb-2">
                    <div class="col-form-label">Companies List</div>
                    <select class="js-example-placeholder-multiple col-sm-12" multiple="multiple">
                        <option value="AL">Alabama</option>
                        <option value="WY">Wyoming</option>
                        <option value="WY">Coming</option>
                        <option value="WY">Hanry Die</option>
                        <option value="WY">John Doe</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({})

import CreateProject from "./CreateProject.vue";
export default {
    name: 'EditProject',
    data() {
        return {
            projectItem: {},
            companies: {},
        };
    },
    components: {
        CreateProject
    },
    props: {
        project: {
            default: null,
        },
    },
    methods: {
        updateProjectItem: function ({ project_title, client_name, start_date, end_date }) {
            this.projectItem.project_title = project_title;
            this.projectItem.client_name = client_name;
            this.projectItem.start_date = start_date;
            this.projectItem.end_date = end_date;
        },
        updateProject: async function() {
            let $this = this;

            try {
                let { data } = await axios.post('/projects/update', {
                    project_id: this.projectItem.id,
                    project_title: this.projectItem.project_title,
                    client_name: this.projectItem.client_name,
                    start_date: this.projectItem.start_date,
                    end_date: this.projectItem.end_date
                })

                if (data.success) {
                    toaster.show(data.message, {theme: 'outline', position: 'top-right', icon: 'times', type: 'success', duration: 2000});

                    $this.back();
                } else {
                    $this.showToastErrorMessage(data.message);
                }

            } catch (error) {
                if (error.response !== undefined) {
                    $this.showToastErrorMessage(error.response.data.message);
                }
            }
        },
        showToastErrorMessage: function(message) {
            toaster.show('Opps... ' + message, {theme: 'outline', position: 'top-right', icon: 'times', type: 'error', duration: 2000});
        },
        back: function () {
            window.location.replace("/projects/list");
        },
        fetchAllCompanies: function() {
            let $this = this;

            axios.get('/companies/list/get', {})
                .then(function (response) {
                    $this.companies = response.data.items;
                })
        },
    },
    mounted() {
        this.projectItem = this.project;
    }
}
</script>
