<template>
    <basic-table>
        <template v-slot:table_cards_header>
            <h5>Projects List</h5>
        </template>
        <template v-slot:table_rows_header>
            <tr class="border-bottom-primary">
                <th scope="col">Id</th>
                <th scope="col">Project title</th>
                <th scope="col">Client name</th>
                <th scope="col">start date</th>
                <th scope="col">end date</th>
                <th scope="col">actions</th>
            </tr>
        </template>
        <template v-slot:table_rows_data>
            <tr v-if="projects === null">
                <td colspan="5" class="text-center">Empty table</td>
            </tr>
            <tr v-else v-for="(project, index) in projects" :key="project.id">
                <td>{{ project.id }}</td>
                <td>{{ project.project_title }}</td>
                <td>{{ project.client_name }}</td>
                <td>{{ project.start_date }}</td>
                <td>{{ project.end_date }}</td>
                <td>
                    <ul class="action">
                        <li class="edit">
                            <a :href="'/projects/edit/' + project.id"><i class="icon-pencil-alt"></i></a>
                        </li>
                        <li class="delete">
                            <a @click="setProjectId(project, index)" data-bs-toggle="modal" data-bs-target="#delete-modal" href="#"><i class="icon-trash"></i></a>
                        </li>
                    </ul>
                </td>
            </tr>
        </template>
    </basic-table>
    <basic-modal title="Edit Project" target-id="edit-modal" set-class="modal-lg">
        <template v-slot:modal_body>
            <create-project
                is-edit="true"
                :project="projectItem"
                @update-project="updateProjectItem"
            />
        </template>
        <template v-slot:first_button>
            <button class="btn btn-outline-info" @click="updateProject()">Save</button>
        </template>
    </basic-modal>
    <basic-modal title="Delete project" target-id="delete-modal" :modal-body=deleteMessage>
        <template v-slot:first_button>
            <button class="btn btn-outline-danger" @click="deleteProject(this.deleteProjectIdRow)">Delete</button>
        </template>
    </basic-modal>
</template>

<script>
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({})

import BasicTable from "../Basic/BasicTable.vue";
import BasicModal from "../Basic/BasicModal.vue";

import CreateProject from "./CreateProject.vue";
export default {
    name: 'ProjectsList',
    data() {
        return {
            projects: null,
            deleteProjectIdRow: null,
            projectItem: {},
            deleteMessage: '',
        };
    },
    components: {
        BasicTable,
        BasicModal,
        CreateProject,
    },
    methods: {
        fetchAll: function() {
            let $this = this;

            axios.get('/projects/list/get', {})
                .then(function (response) {
                    $this.projects = response.data.items;
                })
        },
        updateProject: async function() {
            let $this = this;

            try {
                let { data } = await axios.post('/projects/update', {
                    project_id: $this.projectItem.id,
                    project_title: $this.projectItem.project_title,
                    client_name: $this.projectItem.client_name,
                    start_date: $this.projectItem.start_date,
                    end_date: $this.projectItem.end_date
                })

                if (data.success) {
                    $this.fetchAll();
                    $this.projectItem = {};

                    $('#edit-modal').modal('hide');

                    toaster.show(data.message, {theme: 'outline', position: 'top-right', icon: 'times', type: 'success', duration: 2000});
                } else {
                    $this.showToastErrorMessage(data.message);
                }

            } catch (error) {
                if (error.response !== undefined) {
                    $this.showToastErrorMessage(error.response.data.message);
                }
            }
        },
        deleteProject: async function(projectIdIndex) {
            let $this = this;

            try {
                let { data } = await axios.post('/projects/delete', {
                    project_id: this.projectItem.id,
                })

                if (data.success) {
                    $this.projects.splice(projectIdIndex, 1);
                    $this.projectItem = {};

                    $('#delete-modal').modal('hide');

                    toaster.show(data.message, {theme: 'outline', position: 'top-right', icon: 'times', type: 'success', duration: 2000});
                } else {
                    $this.showToastErrorMessage(data.message);
                }

            } catch (error) {
                if (error.response !== undefined) {
                    $this.showToastErrorMessage(error.response.data.message);
                }
            }
        },
        setProjectId: function(project, index) {
            this.deleteProjectIdRow = index;
            this.projectItem = Object.create(project);

            this.deleteMessage = 'Are you sure, that you want to delete "' + project.project_title + '" ?';
        },
        showToastErrorMessage: function(message) {
            toaster.show('Opps... ' + message, {theme: 'outline', position: 'top-right', icon: 'times', type: 'error', duration: 2000});
        },
        updateProjectItem: function ({ project_title, client_name, start_date, end_date }) {
            this.projectItem.project_title = project_title;
            this.projectItem.client_name = client_name;
            this.projectItem.start_date = start_date;
            this.projectItem.end_date = end_date;
        },
    },
    mounted() {
        this.fetchAll();
    }
}
</script>
