<template>
    <div class="col-sm-12">
        <div class="card">
            <div class="card-body">
                <div class="form theme-form">
                    <div class="row">
                        <div class="col">
                            <div class="mb-3">
                                <label>Name client or company name *</label>
                                <input class="form-control" type="text" :class="inputClasses" placeholder="Name client or company name"
                                       v-model="company_name" @input="validated($event.target.value)">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="mb-3">
                                <label>Email *</label>
                                <input v-model="email" class="form-control" type="text" placeholder="Email">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="mb-3">
                                <label>Contact Person *</label>
                                <input v-model="contact_person" class="form-control" type="text" placeholder="Contact Person">
                            </div>
                        </div>
                    </div>
                    <div v-if="! isEdit" class="row">
                        <div class="col">
                            <div class="text-end">
                                <a class="btn btn-success me-3" target="_blank" @click="add()"> Add</a>
                                <a class="btn btn-danger" href="javascript:void(0)" @click="clearForm">Cancel</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({})

export default {
    name: 'CreateCompany',
    data() {
        return {
            company_nameError: false,
            errors: [],
            company_name: null,
            email: null,
            contact_person: null,
            inputClasses: "is-invalid"
        }
    },
    props: {
        isEdit: {
            default: false,
        },
        company: {
            default: {},
        },
    },
    watch: {
        company: function (newVal) {
            if (this.isEdit) {
                this.company_name = newVal.company_name;
                this.email = newVal.email;
                this.contact_person = newVal.contact_person;

                this.validated();
            }
        },
        company_name: function () {
            this.emitUpdateCompanyItem();
        },
        email: function () {
            this.emitUpdateCompanyItem();
        },
        contact_person: function () {
            this.emitUpdateCompanyItem();
        },
    },
    methods: {
        initialState: function() {
            return {
                company_nameError: false,
                errors: [],
                company_name: null,
                email: null,
                contact_person: null,
                inputClasses: "is-invalid"
            }
        },
        validated: function() {
            if (this.company_name === undefined || this.company_name.length < 5) {
                this.inputClasses = 'is-invalid'
            }
            else {
                this. inputClasses = 'is-valid'
            }
        },
        isValidForm: function () {
            this.errors = [];
            if (this.company_name === null || this.company_name.length < 5) {
                this.company_nameError = true;

                this.showToastErrorMessage('Company name is incorrect.');
                return false;
            }

            if (this.email === null || this.email === "" || !/^[^@]+@\w+(\.\w+)+\w$/.test(this.email)) {
                this.showToastErrorMessage('Email is incorrect.');
                return false;
            }

            if (this.contact_person === null || this.contact_person === "") {
                this.showToastErrorMessage('Contact person is required.');
                return false;
            }

            this.company_nameError = false;
            return true;
        },
        add: async function () {
            let $this = this;

            if (!this.isValidForm()) return;

            try {
                let { data } = await axios.post('/companies/create', {
                    company_name: $this.company_name,
                    email: $this.email,
                    contact_person: $this.contact_person,
                })

                if (data.success) {
                    $this.showToastSuccessMessage(data.message);

                    $this.clearForm();
                } else {
                    $this.showToastErrorMessage(data.message);
                }

            } catch (error) {
                if (error.response !== undefined) {
                    $this.showToastErrorMessage(error.response.data.message);
                }
            }
        },

        clearForm: function () {
            Object.assign(this.$data, this.initialState());
        },

        showToastErrorMessage: function (message) {
            toaster.show('Opps... ' + message, {
                theme: 'outline',
                position: 'top-right',
                icon: 'times',
                type: 'error',
                duration: 2000
            });
        },

        showToastSuccessMessage: function(message) {
            toaster.show(message, {theme: 'outline', position: 'top-right', icon: 'times', type: 'success', duration: 2000});
        },

        emitUpdateCompanyItem: function() {
            this.$emit('update-company', {
                company_name: this.company_name,
                email: this.email,
                contact_person: this.contact_person,
            })
        }
    }
}
</script>
