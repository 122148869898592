<template>
    <div class="col-sm-12">
        <div class="card">
            <div class="card-body">
                <div class="form theme-form">
                    <div class="row">
                        <div class="col">
                            <div class="mb-3">
                                <label>Project Title *</label>
                                <input class="form-control" type="text" :class="inputClasses" placeholder="Project name *"
                                       v-model="title" @input="validated($event.target.value)">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="mb-3">
                                <label>Client name *</label>
                                <input v-model="client_name" class="form-control" type="text" placeholder="Name client or company name">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="mb-3">
                                <label>Starting date</label>
                                <input v-model="start_date" class="datepicker-here form-control" type="date" data-language="en">
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="mb-3">
                                <label>Ending date</label>
                                <input v-model="end_date" class="datepicker-here form-control" type="date" data-language="en">
                            </div>
                        </div>
                    </div>
                    <slot name="buttons_block">
                        <div v-if="! isEdit" class="row">
                            <div class="col">
                                <div class="text-end">
                                    <a class="btn btn-success me-3" target="_blank" @click="add()"> Add</a>
                                    <a class="btn btn-danger" href="javascript:void(0)" @click="clearForm">Cancel</a>
                                </div>
                            </div>
                        </div>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({})

export default {
    name: 'CreateProject',
    data() {
        return {
            titleError: false,
            errors: [],
            title: null,
            client_name: null,
            start_date: null,
            end_date: null,
            inputClasses: "is-invalid"
        };
    },
    props: {
        isEdit: {
            default: false,
        },
        project: {
            default: {},
        },
    },
    watch: {
        project: function (newVal) {
            console.log(newVal, 'create new Val watch');

            if (this.isEdit) {
                this.title = newVal.project_title;
                this.client_name = newVal.client_name;
                this.start_date = newVal.start_date;
                this.end_date = newVal.end_date;

                this.validated();
            }
        },
        title: function () {
            this.emitUpdateProjectItem();
        },
        client_name: function () {
            this.emitUpdateProjectItem();
        },
        start_date: function () {
            this.emitUpdateProjectItem();
        },
        end_date: function () {
            this.emitUpdateProjectItem();
        },
    },
    methods: {
        initialState: function() {
            return {
                titleError: false,
                errors: [],
                title: null,
                client_name: null,
                start_date: null,
                end_date: null,
                inputClasses: "is-invalid"
            }
        },
        validated: function() {
            if (this.title === undefined || this.title.length < 5) {
                this.inputClasses = 'is-invalid'
            }
            else {
                this. inputClasses = 'is-valid'
            }
        },
        isValidForm: function () {
            this.errors = [];
            if (this.title === null || this.title.length < 5) {
                this.titleError = true;

                this.showToastErrorMessage('Project title is incorrect.');
                return false;
            }

            if (this.client_name === null || this.client_name === "") {
                this.showToastErrorMessage('Client name is required.');
                return false;
            }

            this.titleError = false;
            return true;
        },
        add: async function () {
            let $this = this;

            if (!this.isValidForm()) return;

            try {
                let { data } = await axios.post('/projects/create', {
                    project_title: $this.title,
                    client_name: $this.client_name,
                    start_date: $this.start_date,
                    end_date: $this.end_date
                })

                if (data.success) {
                    $this.showToastSuccessMessage(data.message);

                    $this.clearForm();
                } else {
                    $this.showToastErrorMessage(data.message);
                }

            } catch (error) {
                if (error.response !== undefined) {
                    $this.showToastErrorMessage(error.response.data.message);
                }
            }
        },
        clearForm: function () {
            Object.assign(this.$data, this.initialState());
        },
        showToastErrorMessage: function(message) {
            toaster.show('Opps... ' + message, {theme: 'outline', position: 'top-right', icon: 'times', type: 'error', duration: 2000});
        },

        showToastSuccessMessage: function(message) {
            toaster.show(message, {theme: 'outline', position: 'top-right', icon: 'times', type: 'success', duration: 2000});
        },

        emitUpdateProjectItem: function() {
            this.$emit('update-project', {
                project_title: this.title,
                client_name: this.client_name,
                start_date: this.start_date,
                end_date: this.end_date
            })
        }
    }
}
</script>
