<template>
    <div class="modal fade" :id="targetId" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" @click.self="closeOutside">
        <div class="modal-dialog" :class="setClass" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">{{ title }}</h5>
                    <button @click="close" class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <slot name="modal_body">
                    <div class="modal-body" v-html="modalBody"></div>
                </slot>
                <div class="modal-footer">
                    <slot name="modal_footer">
                        <slot name="first_button"></slot>
                        <slot name="second_button">
                            <button @click="close" class="btn btn-outline-dark" type="button" data-bs-dismiss="modal">Close</button>
                        </slot>
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
    name: "BasicModal",
    props: {
        title: {
            default: 'Modal title'
        },
        targetId: {
            default: 'exampleModal'
        },
        modalBody: {
            default: ''
        },
        setClass: {
            default: ''
        },
    },
    methods: {
        close: function() {
            this.$emit('close');
        },
        closeOutside: function() {
            this.close();
        }
    },
    mounted() {
        $(`#${this.targetId}`).on('shown.bs.modal', () => {
            this.$emit('shown');
        });
    },
})
</script>
