<template>
    <basic-table
        show-top-button="true"
        @top-button-click="createUserOpenModal"
    >
        <template v-slot:table_cards_header>
            <h5>Users List</h5>
        </template>
        <template v-slot:table_rows_header>
            <tr class="border-bottom-primary">
                <th scope="col">Id</th>
                <th scope="col">Username</th>
                <th scope="col">Роли</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Projects</th>
                <th scope="col">actions</th>
            </tr>
        </template>
        <template v-slot:table_rows_data>
            <tr v-if="users === null">
                <td colspan="5" class="text-center">Empty table</td>
            </tr>
            <tr v-else v-for="(user, index) in users" :key="user.id">
                <td>{{ user.id }}</td>
                <td>{{ user.username }}</td>
                <td>{{ user.roles.map(role => role.name).join(', ') }}</td>
                <td>{{ user.name }}</td>
                <td>{{ user.email }}</td>
                <td>
                    <ul v-if="user.projects.length > 0">
                        <li v-for="project in user.projects" :key="project.id">
                            {{ project.project_title }}
                        </li>
                    </ul>
                    <p v-else>----</p>
                </td>
                <td>
                    <ul class="action">
                        <li class="edit">
                            <a @click="setUserItemData(user, index)" data-bs-toggle="modal" data-bs-target="#edit-modal" href="#">
                                <i class="icon-pencil-alt"></i>
                            </a>
                        </li>
                        <li class="delete">
                            <a @click="setUserItemData(user, index)" data-bs-toggle="modal" data-bs-target="#delete-modal" href="#">
                                <i class="icon-trash"></i>
                            </a>
                        </li>
                    </ul>
                </td>
            </tr>
        </template>
    </basic-table>
    <basic-modal
        title="Edit User"
        target-id="edit-modal"
        set-class="modal-lg"
        @close="clearUserModalForm"
        @shown="userFormOnOpen"
    >
        <template v-slot:modal_body>
            <create-user
                ref="userForm"
                :is-edit="modalUserIsEditMode"
                :user="userItem"
                :show-buttons="false"
            />
        </template>
        <template v-slot:first_button>
            <button @click="saveUserData" class="btn btn-outline-info">{{ modalUserIsEditMode ? 'Save' : 'Store' }}</button>
        </template>
    </basic-modal>
</template>

<script>
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({})

import BasicTable from "../Basic/BasicTable.vue";
import BasicModal from "../Basic/BasicModal.vue";

import CreateUser from "../Users/CreateUser.vue";

export default {
    name: 'UsersList',
    data() {
        return {
            users: null,
            deleteUserIdItem: null,
            userItem: {},
            deleteMessage: '',
            modalUserIsEditMode: false,
        };
    },
    components: {
        BasicTable,
        BasicModal,
        CreateUser,
    },
    methods: {
        createUserOpenModal: function () {
            this.modalUserIsEditMode = false;

            $('#edit-modal').modal('show');
        },

        fetchAll: function () {
            let $this = this;

            axios.get('/api/users/list/get', {})
                .then(function (response) {
                    $this.users = response.data.items;
                })
        },

        setUserItemData: function (user, index) {
            this.modalUserIsEditMode = true;
            this.deleteUserIdItem = index;

            this.userItem = Object.create(user);

            this.deleteMessage = 'Are you sure, that you want to delete "' + user.username + '" ?';
        },

        saveUserData: function() {
            this.$refs.userForm.store();
        },

        clearUserModalForm: function() {
            this.$refs.userForm.clearForm();
        },

        userFormOnOpen: function() {
            //this.$refs.userForm.onOpen();
        },
    },
    mounted() {
        this.fetchAll();
    },
}
</script>
