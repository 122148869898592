require('./bootstrap');

import {createApp} from 'vue';

import { createRouter, createWebHistory } from 'vue-router'
import LoginPage from "./components/LoginPage.vue";

import ProjectsList from "./components/Projects/ProjectsList.vue";
import CreateProject from "./components/Projects/CreateProject.vue";
import EditProject from "./components/Projects/EditProject.vue";

import CompaniesList from "./components/Companies/CompaniesList.vue";
import CreateCompany from "./components/Companies/CreateCompany.vue";

import UsersList from "./components/Users/UsersList.vue";

import CalendarView from "./components/View/CalendarView.vue";

import NotificationManager from './NotificationManager';

const authId = '#auth';
const pageBodyAppId = '#page_body_app';

const notificationManager = new NotificationManager();
let notify = notificationManager.createNotify();

notificationManager.updateNotify(notify);

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/login', component: LoginPage },
        { path: '/projects/list', component: ProjectsList },
        { path: '/projects/create', component: CreateProject },
        { path: '/companies/list', component: CompaniesList },
        { path: '/companies/create', component: CreateCompany },
        { path: '/users/list', component: UsersList },
    ],
    linkExactActiveClass: 'active',
});

if ($(authId).length > 0) {
    createApp({
        components: {
            LoginPage,
        }
    })
        .use(router)
        .mount(authId);
}

if ($(pageBodyAppId).length > 0) {
    createApp({
        components: {
            ProjectsList,
            CreateProject,
            CompaniesList,
            CreateCompany,
            UsersList,
            EditProject,
            CalendarView,
        }
    })
        .use(router)
        .mount(pageBodyAppId);
}

