<template>
    <basic-table>
        <template v-slot:table_cards_header>
            <h5>Companies List</h5>
        </template>
        <template v-slot:table_rows_header>
            <tr class="border-bottom-primary">
                <th scope="col">Id</th>
                <th scope="col">Company name</th>
                <th scope="col">email</th>
                <th scope="col">contact person</th>
                <th scope="col">actions</th>
            </tr>
        </template>
        <template v-slot:table_rows_data>
            <tr v-if="companies === null">
                <td colspan="5" class="text-center">Empty table</td>
            </tr>
            <tr v-else v-for="(company, index) in companies" :key="company.id">
                <td>{{ company.id }}</td>
                <td>{{ company.company_name }}</td>
                <td>{{ company.email }}</td>
                <td>{{ company.contact_person }}</td>
                <td>
                    <ul class="action">
                        <li class="edit">
                            <a @click="setCompanyItemData(company, index)" data-bs-toggle="modal" data-bs-target="#edit-modal" href="#"><i class="icon-pencil-alt"></i></a>
                        </li>
                        <li class="delete">
                            <a @click="setCompanyItemData(company, index)" data-bs-toggle="modal" data-bs-target="#delete-modal" href="#"><i class="icon-trash"></i></a>
                        </li>
                    </ul>
                </td>
            </tr>
        </template>
    </basic-table>
    <basic-modal title="Edit Company" target-id="edit-modal" set-class="modal-lg">
        <template v-slot:modal_body>
            <create-company
                is-edit="true"
                :company="companyItem"
                @update-company="updateCompanyItem"
            />
        </template>
        <template v-slot:first_button>
            <button class="btn btn-outline-info" @click="updateCompanyData()">Save</button>
        </template>
    </basic-modal>
    <basic-modal title="Delete Company" target-id="delete-modal" :modal-body=deleteMessage>
        <template v-slot:first_button>
            <button class="btn btn-outline-danger" @click="deleteCompany(this.deleteCompanyIdRow)">Delete</button>
        </template>
    </basic-modal>
</template>

<script>
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({})

import BasicTable from "../Basic/BasicTable.vue";
import BasicModal from "../Basic/BasicModal.vue";

import CreateCompany from "./CreateCompany.vue";

export default {
    name: 'CompaniesList',
    data() {
        return {
            companies: null,
            deleteCompanyIdRow: null,
            companyItem: {},
            deleteMessage: '',
        };
    },
    components: {
        BasicTable,
        BasicModal,
        CreateCompany,
    },
    methods: {
        fetchAll: function() {
            let $this = this;

            axios.get('/companies/list/get', {})
                .then(function (response) {
                    $this.companies = response.data.items;
                })
        },
        updateCompanyData: async function() {
            let $this = this;

            try {
                let { data } = await axios.post('/companies/update', {
                    company_id: $this.companyItem.id,
                    company_name: $this.companyItem.company_name,
                    email: $this.companyItem.email,
                    contact_person: $this.companyItem.contact_person,
                })

                if (data.success) {
                    $this.fetchAll();
                    $this.companyItem = {};

                    $('#edit-modal').modal('hide');

                    toaster.show(data.message, {theme: 'outline', position: 'top-right', icon: 'times', type: 'success', duration: 2000});
                } else {
                    $this.showToastErrorMessage(data.message);
                }

            } catch (error) {
                if (error.response !== undefined) {
                    $this.showToastErrorMessage(error.response.data.message);
                }
            }
        },

        deleteCompany: async function(companyIdIndex) {
            let $this = this;

            try {
                let { data } = await axios.post('/companies/delete', {
                    company_id: this.companyItem.id,
                })

                if (data.success) {
                    this.companies.splice(companyIdIndex, 1);
                    $this.companyItem = {};

                    $('#delete-modal').modal('hide');

                    toaster.show(data.message, {theme: 'outline', position: 'top-right', icon: 'times', type: 'success', duration: 2000});
                } else {
                    $this.showToastErrorMessage(data.message);
                }

            } catch (error) {
                if (error.response !== undefined) {
                    $this.showToastErrorMessage(error.response.data.message);
                }
            }
        },

        setCompanyItemData: function(company, index) {
            this.deleteCompanyIdRow = index;
            this.companyItem = Object.create(company);

            this.deleteMessage = 'Are you sure, that you want to delete "' + company.company_name + '" ?';
        },

        showToastErrorMessage: function(message) {
            toaster.show('Opps... ' + message, {theme: 'outline', position: 'top-right', icon: 'times', type: 'error', duration: 2000});
        },

        updateCompanyItem: function ({ company_name, email, contact_person }) {
            this.companyItem.company_name = company_name;
            this.companyItem.email = email;
            this.companyItem.contact_person = contact_person;
        },
    },
    mounted() {
        this.fetchAll();
    }
}
</script>
