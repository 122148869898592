<template>
    <form class="theme-form" @submit.prevent="">
        <h4 class="text-center">Sign in to account</h4>
        <p class="text-center">Enter your username & password to login</p>
        <div class="form-group">
            <label class="col-form-label">Username</label>
            <input class="form-control" v-model="username" type="text" required="" placeholder="username">
        </div>
        <div class="form-group">
            <label class="col-form-label">Password</label>
            <div class="form-input position-relative">
                <input v-model="password" :type="type" class="form-control" name="login[password]" placeholder="*********">
                <div class="show-hide"><span class="show" @click="showPassword"> </span></div>
            </div>
        </div>
        <div class="form-group mb-0">
            <div class="checkbox p-0">
                <input id="checkbox1" type="checkbox">
                <label class="text-muted" for="checkbox1">Remember password</label>
            </div>
            <div class="text-end mt-3">
                <button class="btn btn-primary btn-block w-100" @click="doLogin" type="submit">Sign in</button>
            </div>
        </div>
    </form>
</template>

<script>
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({})

export default {
    name: 'LoginPage',
    data() {
        return {
            username: null,
            password: null,
            type: 'password',
        };
    },
    methods: {
        doLogin: async function () {
            const that = this;
            try {
                const { data } = await axios.post('/login', {
                    username: that.username,
                    password: that.password
                });

                if (data.success) {
                    window.location.href = '/';
                } else {
                    that.showToastErrorMessage('Opps... ' + data.message);
                }

            } catch (error) {
                if (error.response !== undefined) {
                    that.showToastErrorMessage('Opps... ' + error.response.data.message);
                }
            }
        },
        showToastErrorMessage: function(message) {
            toaster.show(message, {theme: 'outline', position: 'top-right', icon: 'times', type: 'error', duration: 2000});
        },
        showPassword: function () {
            this.type = (this.type === 'password') ? 'text' : 'password';
        }
    }
}
</script>
