<template>
    <div class="col-sm-12">
        <div class="card">
            <div class="card-header">
                <slot name="table_cards_header">
                    <h5>Basic Table</h5>
                </slot>
                <div class="button-table-blocks">
                    <slot v-if="showTopButton" name="table_cards_header_buttons">
                        <button @click="topButtonClick" class="btn btn-outline-info">Create</button>
                    </slot>
                </div>
            </div>
            <div class="table-responsive custom-scrollbar">
                <table class="table">
                    <thead>
                        <slot name="table_rows_header">
                            <tr class="border-bottom-primary">
                                <th scope="col">Id</th>
                                <th scope="col">First Name</th>
                                <th scope="col">Last Name</th>
                                <th scope="col">Username</th>
                                <th scope="col">Designation</th>
                                <th scope="col">Company</th>
                                <th scope="col">Language</th>
                                <th scope="col">Country</th>
                            </tr>
                        </slot>
                    </thead>
                    <tbody>
                        <slot name="table_rows_data">
                            <tr :class="data.borderColor" v-for="(data,index) in dataList" :key="index">
                                <th scope="row">{{ data.id }}</th>
                                <td>
                                    <img :src="getImgUrl(data.img)" alt="" class="img-30 me-2 rounded-circle">{{ data.firstName }}
                                </td>
                                <td>{{ data.lastName }}</td>
                                <td>{{ data.userName }}</td>
                                <td>{{ data.role }}</td>
                                <td>{{ data.company }}</td>
                                <td>
                                    <div class="badge " :class="data.badgeClass">
                                        {{ data.language }}
                                    </div>
                                </td>
                                <td>{{ data.country}}</td>
                            </tr>
                        </slot>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BasicTable',
    data() {
        return {
            dataList: [ // Initial sample data
                {
                    "id": 1,
                    "img": "user/1.jpg",
                    "firstName": "Ram Jacob",
                    "lastName": "Wolfe",
                    "userName": "RamJacob@twitter",
                    "role": "Developer",
                    "company": "Apple Inc.",
                    "language": "Php",
                    "badgeClass": "badge-light-danger",
                    "credit": "$3500.00",
                    "borderColor": "border-bottom-secondary",
                    "country": "IND"
                },
            ]
        };
    },
    props: {
        showTopButton: {
            default: false,
        },
    },
    methods: {
        getImgUrl: function(img) {
            return '/' + img;
        },
        topButtonClick: function() {
            this.$emit('top-button-click');
        }
    }
}
</script>

<style scoped>
.button-table-blocks {
    margin-left: auto;
}

.card {
    .card-header {
        display: flex;
        align-content: start;
        align-items: center;
    }
}
</style>
