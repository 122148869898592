<template>
    <div class="col-sm-12">
        <div class="card">
            <div class="card-body">
                <div class="form theme-form">
                    <div class="row">
                        <div class="col">
                            <div class="mb-3">
                                <label>Login <span v-if="! isEdit" class="red-asterisk">*</span></label>
                                <input
                                    v-model="username"
                                    class="form-control input-air-primary"
                                    type="text"
                                    placeholder="User Name"
                                    :disabled="isEdit"
                                    :class="{'is-invalid': errors.username}"
                                >
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="mb-3">
                                <label class="form-label" for="roles">Role <span class="red-asterisk">*</span></label>
                                <select
                                    v-model="selectedRole"
                                    class="form-select input-air-primary digits"
                                    id="roles"
                                    :class="{'is-invalid': errors.role}"
                                >
                                    <option
                                        v-for="role in roles"
                                        :key="role.id"
                                        :value="role.id">{{ role.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="mb-3">
                                <label>User Name <span class="red-asterisk">*</span></label>
                                <input
                                    v-model="name"
                                    class="form-control input-air-primary"
                                    type="text"
                                    placeholder="User Name"
                                    :class="{'is-invalid': errors.name}"
                                >
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="mb-3">
                                <label class="form-label" for="email">Email address <span class="red-asterisk">*</span></label>
                                <input
                                    v-model="email"
                                    class="form-control input-air-primary"
                                    id="email"
                                    type="email"
                                    placeholder="Email: name@example.com"
                                    :class="{'is-invalid': errors.email}"
                                >
                            </div>
                        </div>
                    </div>
                    <div v-if="! isEdit" class="row">
                        <div class="col">
                            <div class="mb-3">
                                <label>Password <span class="red-asterisk">*</span></label>
                                <input v-model="password" class="form-control input-air-primary" type="password" placeholder="Password">
                            </div>
                        </div>
                    </div>
                    <label class="form-label" for="projects">Project</label>
                    <select v-model="selectedProject" class="form-select input-air-primary digits" id="projects">
                        <option
                            v-for="project in projects"
                            :key="project.id"
                            :value="project.id">{{ project.project_title }}
                        </option>
                    </select>
                    <div v-if="showButtons" class="row">
                        <div class="col">
                            <div class="text-end">
                                <a class="btn btn-success me-3" target="_blank" @click="store()"> {{ isEdit ? 'Save' : 'Store' }}</a>
                                <a class="btn btn-danger" @click="clearForm">Cancel</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <alert-loading :loading="loading"></alert-loading>
</template>

<script>
import AlertLoading from "../Basic/AlertLoading.vue";

const { createToaster } = require('@meforma/vue-toaster');
const toaster = createToaster({})

export default {
    name: 'CreateUser',
    components: { AlertLoading },
    data() {
        return {
            loading: false,
            errors: {},
            inputClasses: "",
            roles: [],
            projects: [],
            username: null,
            password: null,
            email: null,
            name: null,
            selectedRole: null,
            selectedProject: null,
        };
    },
    props: {
        isEdit: {
            default: false,
        },
        showButtons: {
            default: false,
        },
        user: {
            default: {},
        },
    },
    watch: {
        user: function (newVal) {
            if (this.isEdit) {
                console.log(newVal.projects);

                this.username = newVal.username;
                this.email = newVal.email;
                this.name = newVal.name

                if (newVal.roles && newVal.roles.length > 0) {
                    this.selectRole(newVal.roles[0].id);
                }

                if (newVal.projects && newVal.projects.length > 0) {
                    this.selectProject(newVal.projects[0].id);
                }

                this.validated();
            }
        },
    },
    methods: {
        initialState() {
            return {
                errors: {},
                inputClasses: "",
                username: null,
                password: null,
                email: null,
                name: null,
                selectedRole: null,
                selectedProject: null,
            }
        },
        validated: function () {
            if (this.username === undefined || this.username === null || this.username.length < 4) {
                this.inputClasses = 'is-invalid'
            } else {
                this.inputClasses = 'is-valid'
            }
        },

        clearForm() {
            Object.assign(this.$data, this.initialState());
        },

        isValidForm() {
            this.errors = {};
            if (this.username === undefined || this.username === null || this.username.length < 4) {
                this.errors.username = true;

                this.showToastErrorMessage('Username is incorrect.');
                return false;
            }

            return true;
        },

        getCreateUserData() {
            return {
                username: this.username,
                email: this.email,
                name: this.name,
                role: this.selectedRole,
                password: this.password,
                project: this.selectedProject,
            };
        },
        getRequestConfig(userData) {
            let url = '/api/users';
            let method = 'post';

            if (this.isEdit) {
                url += `/${this.user.id}`;
                method = 'put';
                delete userData.username;
                delete userData.password;
            }

            return { method, url, data: userData }
        },
        async handleSubmitSuccess(message) {
            this.showToastSuccessMessage(message);
            this.clearForm();
        },
        async handleSubmitError(errors, message) {
            this.errors = errors;
            Object.values(this.errors).flat().forEach(error => {
                this.showToastErrorMessage(`- ${error}`);
            });
            this.showToastErrorMessage(message);
        },
        async store() {
            if (!this.isValidForm()) return;
            let userData = this.getCreateUserData();
            let requestConfig = this.getRequestConfig(userData);

            try {
                let { data } = await axios(requestConfig);
                if (data.success) {
                    this.handleSubmitSuccess(data.message);
                } else {
                    this.showToastErrorMessage(data.message);
                }
            } catch (error) {
                if (error.response) {
                    this.handleSubmitError(error.response.data.errors, error.response.data.message);
                }
            }
        },

        showToastErrorMessage(message) {
            toaster.show('Opps... ' + message, { theme: 'outline', position: 'top-right', icon: 'times', type: 'error', duration: 2000 });
        },

        showToastSuccessMessage(message) {
            toaster.show(message, { theme: 'outline', position: 'top-right', icon: 'times', type: 'success', duration: 2000 });
        },

        async getFormData() {
            try {
                let { data } = await axios.get('/api/users/get-form-data')
                this.roles = data.items.roles;
                this.projects = data.items.projects;
            } catch (error) {
                this.showToastErrorMessage(error.message || 'Failed to fetch roles');
            }
        },

        selectRole(roleId) {
            const newRole = this.user.roles[0];
            let role = this.roles.find(r => r.id === roleId);

            if (!role && newRole) {
                this.roles.push(newRole);
                role = newRole;
            }

            this.selectedRole = role ? role.id : null;
        },

        selectProject(projectId) {
            const newRole = this.user.projects[0];
            let project = this.projects.find(r => r.id === projectId);

            if (!project && newRole) {
                this.projects.push(newRole);
                project = newRole;
            }

            this.selectedProject = project ? project.id : null;
        },

        /** ToDo: Used for call with loading spinner */
        async onOpen() {
            this.loading = true;
            try {
                await this.getFormData();

                if (this.isEdit) {
                    this.selectRole(this.user.roles[0].id);
                }
                this.loading = false;
            } catch (error) {
                this.loading = false;
                this.showToastErrorMessage(error.message || 'Failed to fetch roles');
            }
        },
    },
    mounted() {
        this.getFormData();
    }
}
</script>

<style scoped>
.red-asterisk {
    color: red;
}
</style>
